'use client';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import Floating, { FloatingElement } from '@/components/ui/parallax-floating';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TextRotate } from '@/components/ui/text-rotate';
import { useQuery } from '@tanstack/react-query';
import { SearchIcon } from 'lucide-react';
import { LayoutGroup, motion } from 'motion/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from '../hooks/useTranslation';

interface ScryfallCard {
	id: string;
	name: string;
	image_uris: {
		normal: string;
		[key: string]: string;
	};
}

interface SearchFormData {
	search: string;
}

type SearchType = 'cards' | 'sets' | 'tokens';

const searchTypeConfig: Record<SearchType, { placeholder: string; path: string; queryPrefix?: string }> = {
	cards: {
		placeholder: 'home.hero.search.cards.placeholder',
		path: '/search',
		queryPrefix: 'name:',
	},
	sets: {
		placeholder: 'home.hero.search.sets.placeholder',
		path: '/sets',
	},
	tokens: {
		placeholder: 'home.hero.search.tokens.placeholder',
		path: '/tokens',
	},
};

function HomeHeroFour() {
	const router = useRouter();
	const form = useForm<SearchFormData>();
	const [searchType, setSearchType] = useState<SearchType>('cards');
	const { t } = useTranslation();

	const handleSearch = (data: SearchFormData) => {
		if (!data.search?.trim()) return;
		const config = searchTypeConfig[searchType];
		router.push({
			pathname: config.path,
			query: { s: config.queryPrefix ? `${config.queryPrefix}${data.search}` : data.search },
		});
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			form.handleSubmit(handleSearch)();
		}
	};

	const { data: randomCards = [], isLoading } = useQuery<ScryfallCard[]>({
		queryKey: ['randomCards'],
		queryFn: async () => {
			const response = await fetch('/api/scryfall/random');
			if (!response.ok) {
				throw new Error(t('home.hero.search.cards.error'));
			}
			return response.json();
		},
		refetchOnWindowFocus: false,
		staleTime: 5 * 60 * 1000, // 5 minutes
	});

	return (
		<section className='w-full h-[800px] md:h-screen md:overflow-visible flex flex-col items-center justify-center relative'>
			<Floating sensitivity={-0.5} className='h-full'>
				{randomCards.map((card: ScryfallCard, index: number) => (
					<FloatingElement
						key={card.id}
						depth={[0.5, 1, 4, 2, 1][index]}
						className={
							[
								'top-[15%] left-[2%] md:top-[25%] md:left-[5% hidden md:block',
								'top-[0%] left-[8%] md:top-[6%] md:left-[11%]',
								'top-[90%] left-[6%] md:top-[80%] md:left-[8%]',
								'top-[0%] left-[87%] md:top-[2%] md:left-[80%] hidden md:block',
								'top-[78%] left-[60%] md:top-[68%] md:left-[75%] xl:left-[83%]',
							][index]
						}
					>
						<Link href={`/card/${card.id}`}>
							<motion.img
								src={card.image_uris.normal}
								alt={t('home.hero.images.cardAlt')}
								className={
									[
										'w-[150px] md:w-[200px] rotate-[-30deg] hover:scale-110 transition-transform duration-300',
										'w-[150px] md:w-[200px] rotate-[10deg] hover:scale-110 transition-transform duration-300',
										'w-[150px] md:w-[200px] rotate-[-20deg] hover:scale-110 transition-transform duration-300',
										'w-[150px] md:w-[200px] rotate-[20deg] hover:scale-110 transition-transform duration-300',
										'w-[125px] md:w-[200px] rotate-[5deg] hover:scale-110 transition-transform duration-300',
									][index]
								}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ delay: 0.5 + index * 0.2 }}
							/>
						</Link>
					</FloatingElement>
				))}
			</Floating>

			<div className='flex flex-col justify-center items-center w-[350px] md:w-[500px] lg:w-[700px] z-50 pointer-events-auto'>
				<motion.h1
					className='text-3xl sm:text-5xl md:text-7xl lg:text-8xl text-center w-full justify-center items-center flex-col flex whitespace-pre leading-tight font-bold tracking-tight space-y-1 md:space-y-4'
					animate={{ opacity: 1, y: 0 }}
					initial={{ opacity: 0, y: 20 }}
					transition={{ duration: 0.2, ease: 'easeOut', delay: 0.3 }}
				>
					<LayoutGroup>
						<motion.span layout className='flex whitespace-pre' transition={{ type: 'spring', damping: 30, stiffness: 400 }}>
							<motion.span layout className='flex whitespace-pre' transition={{ type: 'spring', damping: 30, stiffness: 400 }}>
								{t('home.hero.title')}{' '}
							</motion.span>
							<TextRotate
								texts={t('home.hero.rotatingWords')}
								mainClassName='overflow-hidden pr-3 text-green-500 py-0 pb-2 md:pb-4 rounded-xl'
								staggerDuration={0.03}
								staggerFrom='last'
								rotationInterval={3000}
								transition={{ type: 'spring', damping: 30, stiffness: 400 }}
							/>
						</motion.span>
					</LayoutGroup>
				</motion.h1>
				<motion.p
					className='text-sm sm:text-lg md:text-xl lg:text-2xl text-center font-overusedGrotesk pt-4 sm:pt-8 md:pt-10 lg:pt-12 max-w-xl'
					animate={{ opacity: 1, y: 0 }}
					initial={{ opacity: 0, y: 20 }}
					transition={{ duration: 0.2, ease: 'easeOut', delay: 0.5 }}
				>
					{t('home.hero.description')}
				</motion.p>

				<div className='flex flex-col justify-center items-center mt-10 sm:mt-16 md:mt-20 lg:mt-20 w-full'>
					<motion.div
						className='w-full max-w-lg xl:max-w-xl'
						animate={{ opacity: 1, y: 0 }}
						initial={{ opacity: 0, y: 20 }}
						transition={{
							duration: 0.2,
							ease: 'easeOut',
							delay: 0.7,
							scale: { duration: 0.2 },
						}}
					>
						<Tabs
							defaultValue='cards'
							className='w-full mb-6'
							value={searchType}
							onValueChange={(value) => setSearchType(value as SearchType)}
						>
							<TabsList className='flex justify-center'>
								<TabsTrigger
									value='cards'
									className='flex-1'
								>
									{t('home.hero.search.cards.tab')}
								</TabsTrigger>
								<TabsTrigger
									value='sets'
									className='flex-1'
								>
									{t('home.hero.search.sets.tab')}
								</TabsTrigger>
								<TabsTrigger
									value='tokens'
									className='flex-1'
								>
									{t('home.hero.search.tokens.tab')}
								</TabsTrigger>
							</TabsList>
						</Tabs>

						<div className='relative group'>
							<div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4'>
								<SearchIcon className='h-6 w-6 text-gray-400 transition-colors group-hover:text-green-500 group-focus-within:text-green-500' aria-hidden='true' aria-label={t('home.hero.search.aria.searchIcon')} />
							</div>
							<form
								className='relative z-10'
								onSubmit={form.handleSubmit(handleSearch)}
							>
								<Input
									className='w-full pl-12 pr-24 py-7 text-lg bg-white/5 backdrop-blur-sm border-2 border-white/10 shadow-xl rounded-2xl transition-all duration-300 placeholder:text-gray-400 hover:border-white/20 focus:border-green-500 focus:ring-green-500/20 focus:ring-4 focus:bg-white/10'
									placeholder={t(searchTypeConfig[searchType].placeholder)}
									type='search'
									onKeyDown={handleKeyDown}
									autoComplete='off'
									autoCorrect='off'
									autoCapitalize='off'
									spellCheck='false'
									aria-label={t(`home.hero.search.aria.searchInput.${searchType}`)}
									{...form.register('search')}
								/>
								<Button
									type='submit'
									size='sm'
									className='absolute right-2 top-1/2 -translate-y-1/2 bg-green-500/10 text-green-500 hover:bg-green-500/20 transition-colors'
								>
									{t('home.hero.search.button')}
								</Button>
							</form>
							<motion.div
								className='absolute -inset-1 rounded-2xl bg-gradient-to-r from-green-500/20 to-green-500/0 opacity-0 blur transition-opacity duration-500'
								animate={{ opacity: [0, 0.2, 0] }}
								transition={{ duration: 2, repeat: Infinity, repeatDelay: 3 }}
							/>
						</div>
						<motion.p
							className='text-sm text-gray-400 mt-4 text-center'
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ delay: 1 }}
						>
							{t(`home.hero.search.hint.${searchType}`)}
						</motion.p>
						<motion.p
							className='text-base text-green-500 font-medium mt-6 text-center'
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ delay: 1.2 }}
						>
							{t('home.hero.processedCount', { count: '50,000+' }) || '🎉 We\'ve processed over 50,000+ free proxies for the community! 🎉'}
						</motion.p>
					</motion.div>
				</div>
			</div>
		</section>
	);
}

export default HomeHeroFour;
